body {
  background-image: url("background.49f01830.png");
  margin-left: 7em;
  margin-right: 7em;
  font-family: Comfortaa, cursive;
  font-weight: bolder;
}

h1 {
  text-align: center;
  margin-top: 3rem;
  font-size: 4em;
}

.container {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 96vh;
  padding: 1%;
  display: flex;
}

.ui-box {
  background-color: #f4f4f4;
  flex-direction: column;
  align-items: center;
  min-width: 280px;
  max-width: 300px;
  height: auto;
  padding: 20px;
  display: flex;
}

#hero-img {
  max-width: auto;
  opacity: 1;
  height: auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  transition: opacity .1s ease-in-out;
}

.charts-container {
  flex-flow: wrap;
  flex: 75%;
  height: 97vh;
  display: flex;
}

.chart {
  background-color: #f4f4f4;
  flex-direction: row;
  max-width: 49%;
  max-height: 50%;
  margin-bottom: .3%;
  margin-left: .3%;
  display: flex;
}

#playtime {
  color: red;
  visibility: hidden;
  margin-top: 5%;
  font-size: .8rem;
}

#caps-matter {
  font-size: small;
}

.inline-container {
  display: inline-block;
}

.inline-container input {
  width: 7rem;
}

#battletag-id {
  font-size: large;
}

#hero-label {
  flex-flow: wrap;
  width: 7rem;
  font-size: larger;
  display: flex;
}

#hero {
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 8px 12px;
  font-family: Comfortaa, cursive;
  font-size: 1rem;
  transition: all .3s;
  box-shadow: 0 2px 5px #0003;
}

#submit-btn {
  color: #fff;
  cursor: pointer;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  width: 10%;
  padding: 8px 12px;
  font-family: Comfortaa, cursive;
  font-size: 1rem;
  transition: background-color .3s;
}

#submit-btn:hover {
  background-color: #45a049;
  box-shadow: 0 3px 8px #0003;
}

#battletag {
  border: 2px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 28px);
  padding: 8px 12px;
  font-family: Comfortaa, cursive;
  font-size: 1rem;
  transition: all .3s;
  box-shadow: 0 2px 5px #0000001a;
}

#battletag:focus {
  border-color: #4caf50;
  box-shadow: 0 3px 10px #0000004d;
}

#loader {
  visibility: hidden;
  border: 5px solid #5c5c5c;
  border-top-color: #3498db;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: 1s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

h2 {
  font-size: larger;
}

#hero-label, #hero, #battletag-id, #submit-btn, #caps-matter {
  text-align: center;
  width: 100%;
  margin: 5px 0;
  display: block;
}

.btag_form, .inline-container {
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width <= 768px) {
  body {
    background-image: url("background.49f01830.png");
    margin-left: 1em;
    margin-right: 1em;
    font-family: Comfortaa, cursive;
    font-weight: bolder;
  }

  .container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 150vh;
    display: flex;
  }

  .ui-box {
    order: 1;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  .charts-container {
    flex-direction: row;
    order: 2;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }

  .chart {
    max-height: none;
    margin-bottom: 10px;
    padding: 0;
  }

  #hero-img {
    display: none;
  }
}
/*# sourceMappingURL=index.c35efe97.css.map */
